import AksIcon from 'assets/images/capabilities/AKS.svg';
import CyclecloudIcon from 'assets/images/capabilities/CyclecloudIcon.svg';
import OsduIcon from 'assets/images/capabilities/OSDU.png';
import SAMIcon from 'assets/images/capabilities/SAM.svg';
import StandardIcon from 'assets/images/capabilities/filtred.svg';
import HsmIcon from 'assets/images/capabilities/hsm.svg';
import KeyVaultIcon from 'assets/images/capabilities/keyvault.svg';
import InternetIcon from 'assets/images/capabilities/net.svg';
import DesktopIcon from 'assets/images/capabilities/netblocked.svg';
import NexusIcon from 'assets/images/capabilities/nexusIcon.png';
import { SiteCapabilityDTO, SiteInfoDTO } from 'types';
import './site-capability.scss';


const icons = {
  use_osdu: OsduIcon,
  use_aks: AksIcon,
  use_cyclecloud: CyclecloudIcon,
  use_nexus: NexusIcon,
  use_sam: SAMIcon,
  'sismage-desktop': DesktopIcon,
  'sismage-vdr': StandardIcon,
  'sismage-standard': StandardIcon,
  'sismage-developer': StandardIcon,
  'sismage-internet': InternetIcon,
  hsm: HsmIcon,
  keyvault: KeyVaultIcon,
};

const capabilityNames = {
  use_osdu: 'OSDU',
  used_network: 'Network',
  use_aks: 'AKS',
  use_cyclecloud: 'Cyclecloud',
  use_nexus: 'Nexus',
  use_storage_encryption: 'Storage Encryption',
  storage_encryption_type: 'Storage Encryption Type',
  use_sam: 'SAM',
};

const capabilityStatus = {
  true: 'enabled',
  false: 'disabled',
  none: 'disabled',
  default: 'default',
};

type Props = {
  siteInfo: SiteInfoDTO;
};

const SiteCapability = ({ siteInfo }: Props) => {
  if (siteInfo.capabilities === null) {
    return <div className='nocapabilitysite'>no capability on this site </div>;
  }

  const capabilities: Map<string, string> = new Map(siteInfo.capabilities.map(capability => [capability.capabilityName, capability.capabilityStatus]));

  return (
    <div className='site-capability-block'>
      <div className='site-capability-block__title'>Capabilities</div>
      <div className='site-capability-block_content'>
        {siteInfo.capabilities.sort((c1, c2) => capabilityNames[c1.capabilityName].localeCompare(capabilityNames[c2.capabilityName]))
          .map(capability => {
            if (capabilityStatus[capability.capabilityStatus] === 'disabled') {
              return null;
            }
            const Icon = getCapabilityIcon(capability, capabilities);

            if (Icon == null) {
              return null;
            }
            return (
              <div className='item-capability'>
                <div className='icon-item'><img src={Icon} className='site-capability-block_content__capability-icon' alt={capability.capabilityName} width='50' height='50' /></div>
                <div className='label-item-capability'>{capabilityNames[capability.capabilityName]}</div>
                <div className='value-item-capability'>
                  {capability.capabilityStatus === 'true' || capability.capabilityStatus === 'false' || capability.capabilityStatus === 'none' ? '' : capability.capabilityStatus}
                </div>

              </div>

            );
          })}
      </div>
    </div>
  );
};

function getCapabilityIcon(capability: SiteCapabilityDTO, capabilities: Map<string, string>): string | null {
  if (capability.capabilityName === 'used_network') {
    return icons[capability.capabilityStatus];
  }

  if (capability.capabilityName === 'storage_encryption_type') {
    if (capabilities.get('use_storage_encryption') === 'true') {
      console.log('storage_encryption_type', capability.capabilityStatus);
      return icons[capability.capabilityStatus];
    } else {
      return null;
    }
  }
  return icons[capability.capabilityName];
}

export default SiteCapability;